import { IconX } from "@tabler/icons-react";
import { Button, Grid } from "@mantine/core";

function Unknown({ strategy, removeStrategy }) {
  return (
    <Grid>
      <Grid.Col lg={3}>
        <Button
          rightIcon={<IconX />}
          variant="white"
          color="dark"
          onClick={() => removeStrategy(strategy)}
        >
          {strategy.name}
        </Button>
      </Grid.Col>
    </Grid>
  );
}

export default Unknown;
