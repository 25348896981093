import { React } from "react";
import { Select, Text } from "@mantine/core";

export default function AmountUsdPicker({ value, onChange }) {
  return (
    <Select
      label={<Text fw={700}>Amount ($)</Text>}
      maxDropdownHeight={400}
      placeholder="Amount ($)"
      data={["0", "10", "50", "100", "250", "500", "1000", "2500", "5000"]}
      value={value}
      onChange={onChange}
    />
  );
}
