import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

export default function ProtectedRoute({ children }) {
  const isAuthorized = useSelector((state) => state.auth.isAuthorized);

  if (isAuthorized) {
    return children;
  } else {
    return <Navigate to="/login" replace={true} />;
  }
}
