import axios from "axios";

import SW from "./sw";
import Firebase from "./firebase";

export default class PushNotifications {
  static isSubscribed;
  static async subscribe() {
    const registration = await SW.getRegistration();

    const token = await Firebase.getFcmRegistrationToken({
      swRegistration: registration,
    });

    await axios.post("/api/subscribe-to-fcm-topic", {
      topic: "all-devices",
      fcmRegistrationToken: token,
    });

    console.info("Subscription to `all-devices` topic is successfully added");

    localStorage.setItem("is_push_notifications_enabled", 1);
    localStorage.setItem("last_fcm_token", token);

    return token;
  }
  static async unsubscribe() {
    const registration = await SW.getRegistration();

    // const token = await Firebase.getFcmRegistrationToken({
    //   swRegistration: registration,
    // });

    const token = localStorage.getItem("last_fcm_token");

    await axios.post("/api/unsubscribe-from-fcm-topic", {
      topic: "all-devices",
      fcmRegistrationToken: token,
    });

    await SW.unregister();

    console.info("Subscription to `all-devices` topic is successfully removed");

    localStorage.removeItem("is_push_notifications_enabled");
  }
}
