import { React } from "react";
import { TextInput, Select, Text } from "@mantine/core";

export default function AmountPicker({ value, onChange }) {
  return (
    <TextInput
      label={<Text fw={700}>Amount</Text>}
      placeholder="Amount"
      value={value}
      onChange={onChange}
    />
  );
}
