import createRootSaga from "./common/helpers/createRootSaga";

import { login, logout } from "./common/data/auth/sagas";
import manageTokens from "./common/data/tokens/sagas";
import {
  createOrder,
  orderBuy,
  orderSell,
  orderStop,
  orderResume,
  orderClose,
  orderDelete,
  updateOrder,
  manageOrders,
  manageFakeOrders,
} from "./common/data/orders/sagas";
import {
  watchPrices,
  subscribeToPrice,
  unsubscribeFromPrice,
  unsubscribeById,
} from "./common/data/currentPrice/sagas";

export default createRootSaga(
  createOrder,
  orderBuy,
  orderSell,
  orderStop,
  orderResume,
  orderDelete,
  orderClose,
  updateOrder,
  manageTokens,
  manageOrders,
  manageFakeOrders,
  login,
  logout,
  watchPrices,
  subscribeToPrice,
  unsubscribeFromPrice,
  unsubscribeById
);
