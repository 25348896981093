import { createSlice } from "@reduxjs/toolkit";

export const LOGIN = "AUTH::LOGIN";
export const LOGOUT = "AUTH::LOGOUT";

export const login = ({ password } = {}) => ({ type: LOGIN, password });
export const logout = () => ({ type: LOGOUT });

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    isAuthorized: !!localStorage.getItem("isAuthorized"),
    mongoJwt: localStorage.getItem("mJwt"),
  },
  reducers: {
    setIsAuthorized: (state, action) => {
      state.isAuthorized = action.payload;
    },
    setMongoJwt: (state, action) => {
      state.mongoJwt = action.payload;
    },
  },
});
