export default class SW {
  static init() {
    navigator.serviceWorker.oncontrollerchange = () => {
      console.log("new sw is activated");
    };
  }
  static async getRegistration() {
    if (!("serviceWorker" in navigator)) {
      throw new Error("Browser doesnt support service worker");
    }

    let registration = await navigator.serviceWorker.getRegistration("/");

    if (registration) {
      console.log("page has a service worker installed");
      return registration;
    }

    registration = await navigator.serviceWorker.register("/sw.js", {
      scope: "/",
    });

    console.log("new service worker is installed from scratch");

    return registration;
  }
  static async unregister() {
    if (!("serviceWorker" in navigator)) {
      throw new Error("Browser doesnt support service worker");
    }

    const registrations = await navigator.serviceWorker.getRegistrations();

    for (let reg of registrations) {
      const isUnregistered = await reg.unregister();

      if (isUnregistered) console.info("Unregistered service worker");
    }
  }
}

document.addEventListener("DOMContentLoaded", SW.init);
