import { Button } from "@mantine/core";
import {
  ORDER_BUY,
  ORDER_SELL,
  ORDER_STOP,
  ORDER_RESUME,
  ORDER_CLOSE,
  ORDER_DELETE,
  orderBuy,
  orderClose,
  orderDelete,
  orderResume,
  orderSell,
  orderStop,
} from "../../common/data/orders/redux";
import {
  IconCheck,
  IconPlayerPause,
  IconPlayerPlay,
  IconTrash,
} from "@tabler/icons-react";
import { useDispatch, useSelector } from "react-redux";

export function BuyButton({ order, ...props }) {
  const dispatch = useDispatch();

  const buyProcess = useSelector(
    (state) => state.processes[`${ORDER_BUY}-${order._id}`]
  );
  const sellProcess = useSelector(
    (state) => state.processes[`${ORDER_SELL}-${order._id}`]
  );
  const stopProcess = useSelector(
    (state) => state.processes[`${ORDER_STOP}-${order._id}`]
  );
  const resumeProcess = useSelector(
    (state) => state.processes[`${ORDER_RESUME}-${order._id}`]
  );
  const closeProcess = useSelector(
    (state) => state.processes[`${ORDER_CLOSE}-${order._id}`]
  );
  const deleteProcess = useSelector(
    (state) => state.processes[`${ORDER_DELETE}-${order._id}`]
  );

  let { isFake } = order;

  return (
    <Button
      w="100%"
      variant="light"
      color={isFake ? "gray" : "teal"}
      loading={buyProcess?.inProcess}
      onClick={() => {
        if (
          [
            buyProcess,
            sellProcess,
            stopProcess,
            resumeProcess,
            closeProcess,
            deleteProcess,
          ].some((process) => process?.inProcess)
        ) {
          return;
        }

        dispatch(orderBuy({ id: order._id }));
      }}
      {...props}
    >
      Buy
    </Button>
  );
}
export function SellButton({ order, ...props }) {
  const dispatch = useDispatch();

  const buyProcess = useSelector(
    (state) => state.processes[`${ORDER_BUY}-${order._id}`]
  );
  const sellProcess = useSelector(
    (state) => state.processes[`${ORDER_SELL}-${order._id}`]
  );
  const stopProcess = useSelector(
    (state) => state.processes[`${ORDER_STOP}-${order._id}`]
  );
  const resumeProcess = useSelector(
    (state) => state.processes[`${ORDER_RESUME}-${order._id}`]
  );
  const closeProcess = useSelector(
    (state) => state.processes[`${ORDER_CLOSE}-${order._id}`]
  );
  const deleteProcess = useSelector(
    (state) => state.processes[`${ORDER_DELETE}-${order._id}`]
  );

  let { isFake } = order;

  return (
    <Button
      w="100%"
      variant="light"
      color={isFake ? "gray" : "red"}
      loading={sellProcess?.inProcess}
      onClick={() => {
        if (
          [
            buyProcess,
            sellProcess,
            stopProcess,
            resumeProcess,
            closeProcess,
            deleteProcess,
          ].some((process) => process?.inProcess)
        ) {
          return;
        }

        dispatch(orderSell({ id: order._id }));
      }}
      {...props}
    >
      Sell
    </Button>
  );
}

export function StopButton({ order, ...props }) {
  const dispatch = useDispatch();

  const buyProcess = useSelector(
    (state) => state.processes[`${ORDER_BUY}-${order._id}`]
  );
  const sellProcess = useSelector(
    (state) => state.processes[`${ORDER_SELL}-${order._id}`]
  );
  const stopProcess = useSelector(
    (state) => state.processes[`${ORDER_STOP}-${order._id}`]
  );
  const resumeProcess = useSelector(
    (state) => state.processes[`${ORDER_RESUME}-${order._id}`]
  );
  const closeProcess = useSelector(
    (state) => state.processes[`${ORDER_CLOSE}-${order._id}`]
  );
  const deleteProcess = useSelector(
    (state) => state.processes[`${ORDER_DELETE}-${order._id}`]
  );

  let { isFake } = order;

  return (
    <Button
      w="100%"
      variant="light"
      color={isFake ? "gray" : "gray"}
      loading={stopProcess?.inProcess}
      onClick={() => {
        if (
          [
            buyProcess,
            sellProcess,
            stopProcess,
            resumeProcess,
            closeProcess,
            deleteProcess,
          ].some((process) => process?.inProcess)
        ) {
          return;
        }

        dispatch(orderStop({ id: order._id }));
      }}
      leftIcon={<IconPlayerPause size="1rem" />}
      {...props}
    >
      Stop
    </Button>
  );
}
export function ResumeButton({ order, ...props }) {
  const dispatch = useDispatch();

  const buyProcess = useSelector(
    (state) => state.processes[`${ORDER_BUY}-${order._id}`]
  );
  const sellProcess = useSelector(
    (state) => state.processes[`${ORDER_SELL}-${order._id}`]
  );
  const stopProcess = useSelector(
    (state) => state.processes[`${ORDER_STOP}-${order._id}`]
  );
  const resumeProcess = useSelector(
    (state) => state.processes[`${ORDER_RESUME}-${order._id}`]
  );
  const closeProcess = useSelector(
    (state) => state.processes[`${ORDER_CLOSE}-${order._id}`]
  );
  const deleteProcess = useSelector(
    (state) => state.processes[`${ORDER_DELETE}-${order._id}`]
  );

  let { isFake } = order;

  return (
    <Button
      w="100%"
      variant="light"
      color={isFake ? "gray" : "teal"}
      loading={resumeProcess?.inProcess}
      onClick={() => {
        if (
          [
            buyProcess,
            sellProcess,
            stopProcess,
            resumeProcess,
            closeProcess,
            deleteProcess,
          ].some((process) => process?.inProcess)
        ) {
          return;
        }

        dispatch(orderResume({ id: order._id }));
      }}
      leftIcon={<IconPlayerPlay size="1rem" />}
      {...props}
    >
      Resume
    </Button>
  );
}

export function CloseButton({ order, ...props }) {
  const dispatch = useDispatch();

  const buyProcess = useSelector(
    (state) => state.processes[`${ORDER_BUY}-${order._id}`]
  );
  const sellProcess = useSelector(
    (state) => state.processes[`${ORDER_SELL}-${order._id}`]
  );
  const stopProcess = useSelector(
    (state) => state.processes[`${ORDER_STOP}-${order._id}`]
  );
  const resumeProcess = useSelector(
    (state) => state.processes[`${ORDER_RESUME}-${order._id}`]
  );
  const closeProcess = useSelector(
    (state) => state.processes[`${ORDER_CLOSE}-${order._id}`]
  );
  const deleteProcess = useSelector(
    (state) => state.processes[`${ORDER_DELETE}-${order._id}`]
  );

  let { isFake } = order;

  return (
    <Button
      w="100%"
      variant="light"
      color={isFake ? "gray" : "gray"}
      loading={closeProcess?.inProcess}
      onClick={() => {
        if (
          [
            buyProcess,
            sellProcess,
            stopProcess,
            resumeProcess,
            closeProcess,
            deleteProcess,
          ].some((process) => process?.inProcess)
        ) {
          return;
        }

        dispatch(orderClose({ id: order._id }));
      }}
      leftIcon={<IconCheck size="1rem" />}
      {...props}
    >
      Close
    </Button>
  );
}
export function DeleteButton({ order, ...props }) {
  const dispatch = useDispatch();

  const buyProcess = useSelector(
    (state) => state.processes[`${ORDER_BUY}-${order._id}`]
  );
  const sellProcess = useSelector(
    (state) => state.processes[`${ORDER_SELL}-${order._id}`]
  );
  const stopProcess = useSelector(
    (state) => state.processes[`${ORDER_STOP}-${order._id}`]
  );
  const resumeProcess = useSelector(
    (state) => state.processes[`${ORDER_RESUME}-${order._id}`]
  );
  const closeProcess = useSelector(
    (state) => state.processes[`${ORDER_CLOSE}-${order._id}`]
  );
  const deleteProcess = useSelector(
    (state) => state.processes[`${ORDER_DELETE}-${order._id}`]
  );

  let { isFake } = order;

  return (
    <Button
      w="100%"
      variant="light"
      color={isFake ? "gray" : "gray"}
      loading={deleteProcess?.inProcess}
      onClick={() => {
        if (
          [
            buyProcess,
            sellProcess,
            stopProcess,
            resumeProcess,
            closeProcess,
            deleteProcess,
          ].some((process) => process?.inProcess)
        ) {
          return;
        }

        dispatch(orderDelete({ id: order._id }));
      }}
      leftIcon={<IconTrash size="1rem" />}
      {...props}
    >
      Delete
    </Button>
  );
}
