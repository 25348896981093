import { React } from "react";
import { Autocomplete, Text } from "@mantine/core";

export default function SymbolPicker({ value, onChange }) {
  return (
    <Autocomplete
      defaultValue={value}
      label={<Text fw={700}>Symbol</Text>}
      placeholder="symbol"
      data={[]}
      onChange={onChange}
    />
  );
}
