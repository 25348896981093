import {
  take,
  fork,
  cancel,
  call,
  cancelled,
  put,
  delay,
} from "redux-saga/effects";

import Mongo from "../../sevices/mongo";

import { tokensSlice, WATCH_TOKENS } from "./redux";

function* fetchTokens({ delayTime } = {}) {
  if (delayTime) {
    yield delay(delayTime);
  }

  const collection = Mongo.client.db(Mongo.tokensDb).collection("bestTokens");

  let result = yield call(collection.find.bind(collection), {});

  result = result && result.filter((token) => token.stats !== "NO_DATA");

  yield put(tokensSlice.actions.update({ data: result || [] }));

  return collection;
}

function* watchTokens() {
  try {
    if (!Mongo.client) {
      yield call(Mongo.connect);
    }

    const collection = yield call(fetchTokens);

    const generator = collection.watch();

    let lastFetchTask;

    while (true) {
      const { value: change } = yield generator.next();

      if (!change) {
        break;
      }

      if (
        change.operationType === "delete" ||
        change.operationType === "insert" ||
        change.operationType === "update"
      ) {
        if (lastFetchTask) {
          yield cancel(lastFetchTask);
        }

        lastFetchTask = yield fork(fetchTokens, { delayTime: 2000 });
      }

      // console.log(change);
    }
  } catch (e) {
    console.log(e);
  } finally {
    if (yield cancelled()) {
      console.log("watchTokens is cancelled");
    }
  }
}

export default function* manageTokens() {
  while (true) {
    yield take(WATCH_TOKENS);

    const task = yield fork(watchTokens);

    const { payload } = yield take("auth/setIsAuthorized");

    if (!payload) {
      yield cancel(task);
    }
  }
}
