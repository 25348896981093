import { initializeApp } from "firebase/app";
import { getToken, getMessaging, onMessage } from "firebase/messaging";

export default class Firebase {
  static app;
  static messaging;

  static vapidKey =
    "BDs4O8TlMUGJ2GWWipiwMK5z9o3MI-z-Ys4tYeIT4sp3m-GFe-VgMZB9zdkR_2Z4zgCIXRGQMigGdAgd6cF2qbc";
  static config = {
    apiKey: "AIzaSyDnC2UjUpoN7af2B1NpQQ_oBjNImSZz9yY",
    authDomain: "tdbot-f675d.firebaseapp.com",
    projectId: "tdbot-f675d",
    storageBucket: "tdbot-f675d.appspot.com",
    messagingSenderId: "705449680906",
    appId: "1:705449680906:web:c9e595cffa710cf47a7ff3",
    measurementId: "G-TKT7BC3LM8",
  };

  static init() {
    Firebase.app = initializeApp(Firebase.config);
    Firebase.messaging = getMessaging(Firebase.app);
  }

  static async getFcmRegistrationToken({ swRegistration }) {
    return await getToken(Firebase.messaging, {
      vapidKey: Firebase.vapidKey,
      serviceWorkerRegistration: swRegistration,
    });
  }

  static onForegroundMessage = () =>
    new Promise((resolve) =>
      onMessage(Firebase.messaging, (payload) => resolve(payload))
    );
}

Firebase.init();
