import { Anchor, Button, createStyles, Flex, Menu } from "@mantine/core";
import { Notifications, notifications } from "@mantine/notifications";

import Header from "./Header";
import Main from "./Main";
import Footer from "./Footer";
import { useEffect } from "react";
import Firebase from "../../sevices/firebase";
import { NavLink } from "react-router-dom";

export default function AppShell({ children }) {
  useEffect(() => {
    const watchForegroundMessages = async () => {
      while (true) {
        const {
          data: { title, body, url },
        } = await Firebase.onForegroundMessage();

        notifications.show({
          title,
          color: "green",
          autoClose: 6000,
          message: (
            <NavLink to={url}>
              <Button variant="subtle" color="green">
                Open Trade page
              </Button>
            </NavLink>
          ),
        });
      }
    };

    watchForegroundMessages();
  }, []);

  return (
    <Flex h="100%" direction="column">
      <Header style={{ flexShrink: 0, zIndex: 100 }} />
      <Main style={{ flexGrow: 1 }}>{children}</Main>
      <Footer style={{ flexShrink: 0 }} />
      <Notifications position="top-right" zIndex={9999} />
    </Flex>
  );
}
