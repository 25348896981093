import { all, spawn, call } from "redux-saga/effects";

// https://redux-saga.js.org/docs/advanced/RootSaga#keeping-everything-alive

export default function createRootSaga(...sagas) {
  // root saga function
  return function* () {
    yield all(
      sagas.map((saga) => {
        return spawn(function* () {
          while (true) {
            try {
              yield call(saga);
              break;
            } catch (e) {
              alert(e);
              console.log(e);
            }
          }
        });
      })
    );
  };
}
