import { createSlice } from "@reduxjs/toolkit";

export const dailyStatsSlice = createSlice({
  name: "daily_tokens",
  initialState: {
    ui: {
      rollingMaxGrowth: {
        showType: "distinct", // group | distinct
        dateType: "today", // today | yesterday | specific_day [for distinct]
        date: new Date(), // date, used [for distinct]
        last: "3", // 3 (last 3 days) / 7 (last week) / 30 (last month) [for group]
        diff: "15", // % growth
        refreshData: false,
      },
      intervalSlides: {
        showType: "distinct", // group | distinct
        dateType: "today", // today | yesterday | specific_day [for distinct]
        date: new Date(), // date, used [for distinct]
        last: "3", // 3 (last 3 days) / 7 (last week) / 30 (last month) [for group]
        diff: "15", // % growth
        refreshData: false,
        interval: "d1",
        sma: "20",
        intervalNumber: "1",
        unit: "d",
      },
      fullSlides: {
        diff: "15", // % growth
        refreshData: false,
        interval: "d1",
        sma: "20",
        intervalNumber: "1",
        unit: "d",
      },
    },
    data: { daily: null },
  },
  reducers: {
    updateRollingMaxGrowthUi: (state, action) => {
      state.ui.rollingMaxGrowth = {
        ...state.ui.rollingMaxGrowth,
        ...action.payload,
      };
    },
    updateIntervalSlidesUi: (state, action) => {
      state.ui.intervalSlides = {
        ...state.ui.intervalSlides,
        ...action.payload,
      };
    },
    updateFullSlidesUi: (state, action) => {
      state.ui.fullSlides = {
        ...state.ui.fullSlides,
        ...action.payload,
      };
    },
    updateDailyData: (state, action) => {
      state.data.daily = action.payload;
    },
    updateFullSlidesData: (state, action) => {
      state.data.fullSlides = action.payload;
    },
  },
});
