import React from "react";
import { Box, Center, TextInput, Group, Button } from "@mantine/core";
import { useSelector, useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import { useForm } from "@mantine/form";
import { Alert } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons-react";

import { login, LOGIN } from "../common/data/auth/redux";

export default function Login() {
  const dispatch = useDispatch();

  const isAuthorized = useSelector((state) => state.auth.isAuthorized);
  const loginProcess = useSelector((state) => state.processes[LOGIN]);

  const form = useForm({
    initialValues: {
      password: "",
    },
    validate: {
      password: (value) => {
        if (!value) {
          return "Password is required";
        }
      },
    },
  });

  const submit = async (e) => {
    e.preventDefault();

    const result = form.validate();

    if (result.hasErrors) {
      return;
    }

    const password = form.values.password;

    dispatch(login({ password }));
  };

  if (isAuthorized) {
    return <Navigate to="/" />;
  }

  return (
    <Center>
      <Box miw={250} mx="auto">
        <form onSubmit={submit}>
          {" "}
          <TextInput
            type="password"
            label="Password"
            placeholder="Password"
            mb="1rem"
            {...form.getInputProps("password")}
          />
          {loginProcess?.hasError && (
            <Alert
              icon={<IconAlertCircle size="1rem" />}
              title="Error!"
              color="red"
              mb="1rem"
            >
              {loginProcess.errorMessage}
            </Alert>
          )}
          <Group position="center">
            <Button
              type="submit"
              loading={loginProcess?.inProcess}
              disabled={loginProcess?.inProcess}
              w="100%"
            >
              Login
            </Button>
          </Group>
        </form>
      </Box>
    </Center>
  );
}
