import { Container, Flex, Footer, Text } from "@mantine/core";

export default function AppFooter({ ...props }) {
  return (
    <Footer height={60} {...props} zIndex={100}>
      <Container size="110rem" h="100%">
        <Flex justify="center" align="center" h="100%">
          <Text fw={700}>Bismillah {new Date().getFullYear()}</Text>
        </Flex>
      </Container>
    </Footer>
  );
}
