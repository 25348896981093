import { useState } from "react";
import { useInView } from "react-intersection-observer";

export default function TradingViewChartWidget({
  symbol = "BTCUSDT",
  platform = "BINANCE",
  width = "300px",
  height = "300px",
  interval = "1",
}) {
  const [show, setShow] = useState(false);

  const { ref } = useInView({
    threshold: 0,
    triggerOnce: true,
    onChange: (inView) => {
      if (inView) setShow(true);
    },
  });

  return (
    <div ref={ref}>
      {show && (
        <iframe
          height={height}
          width={width}
          src={`https://www.tradingview.com/widgetembed/?frameElementId=advanced-chart&symbol=${platform}:${symbol}&interval=${interval}&hidesidetoolbar=0&timezone=Europe/Istanbul&hidevolume=1&symboledit=1&saveimage=1&backgroundColor=#ffffff&gridColor=rgba(42, 46, 57, 0.06)&studies=[]&hideideas=1&theme=light&style=1&studies_overrides={}&overrides={}&enabled_features=[]&disabled_features=[]&locale=ru&utm_source=ru.tradingview.com&utm_medium=widget_new&utm_campaign=chart&utm_term=BINANCE:${symbol}#{"page-uri":"ru.tradingview.com/widget/"}`}
          frameBorder="0"
        ></iframe>
      )}
    </div>
  );
}
