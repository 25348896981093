import { createSlice } from "@reduxjs/toolkit";

export const WATCH_TOKENS = "TOKENS::WATCH";

export const watchTokens = ({} = {}) => ({
  type: WATCH_TOKENS,
});

export const tokensSlice = createSlice({
  name: "tokens",
  initialState: { inProcess: false, hasError: false, error: "", data: [] },
  reducers: {
    update: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});
