import { useState } from "react";
import {
  Container,
  Header,
  Flex,
  Group,
  Button,
  Avatar,
  Burger,
  createStyles,
  Menu,
} from "@mantine/core";
import { IconBrandCashapp, IconSettings } from "@tabler/icons-react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { logout } from "../../../data/auth/redux";

const useStyles = createStyles((theme) => ({
  links: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },

  dropdownMenu: {
    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },
}));

export default function AppHeader({ ...props }) {
  const [isMenuOpened, setIsMenuOpened] = useState();

  const { classes, cx } = useStyles();

  const isAuthorized = useSelector((state) => state.auth.isAuthorized);

  const dispatch = useDispatch();

  return (
    <Header height={60} {...props}>
      <Container size="110rem" h="100%">
        <Flex justify="space-between" align="center" h="100%">
          <NavLink to="/">
            <Avatar color="blue" radius="sm">
              <IconBrandCashapp size="1.5rem" />
            </Avatar>
          </NavLink>
          {isAuthorized && (
            <Group className={classes.links}>
              {/*<NavLink to="/">*/}
              {/*  <Button variant="outline">Live Stats</Button>*/}
              {/*</NavLink>*/}
              {/*<NavLink to="/daily">*/}
              {/*  <Button variant="outline">Daily Stats</Button>*/}
              {/*</NavLink>*/}
              <NavLink to="/trade">
                <Button variant="outline">Trade</Button>
              </NavLink>
              {/*<NavLink to="/account">*/}
              {/*  <Button variant="outline">Account</Button>*/}
              {/*</NavLink>*/}
              <NavLink to="/settings">
                <Button variant="outline">
                  <IconSettings />
                </Button>
              </NavLink>
              <Button
                variant="outline"
                color="red"
                onClick={() => dispatch(logout())}
              >
                Logout
              </Button>
            </Group>
          )}
          {isAuthorized && (
            <Menu
              shadow="md"
              width={200}
              className={classes.dropdownMenu}
              onClose={() => setIsMenuOpened(false)}
            >
              <Menu.Target>
                <Burger
                  opened={isMenuOpened}
                  onClick={() => setIsMenuOpened((value) => !value)}
                />
              </Menu.Target>

              <Menu.Dropdown className={classes.dropdownMenu}>
                {/*<NavLink to="/" style={{ textDecoration: "none" }}>*/}
                {/*  <Menu.Item>Live Stats</Menu.Item>*/}
                {/*</NavLink>*/}
                {/*<NavLink to="/daily" style={{ textDecoration: "none" }}>*/}
                {/*  <Menu.Item>Daily Stats</Menu.Item>*/}
                {/*</NavLink>*/}
                <NavLink to="/trade" style={{ textDecoration: "none" }}>
                  <Menu.Item>Trade</Menu.Item>
                </NavLink>
                {/*<NavLink to="/account" style={{ textDecoration: "none" }}>*/}
                {/*  <Menu.Item>Account</Menu.Item>*/}
                {/*</NavLink>*/}
                <NavLink to="/settings" style={{ textDecoration: "none" }}>
                  <Menu.Item>Settings</Menu.Item>
                </NavLink>
                <Menu.Divider />
                <Menu.Item color="red" onClick={() => dispatch(logout())}>
                  Logout
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          )}
        </Flex>
      </Container>
    </Header>
  );
}
