import { call, take, put } from "redux-saga/effects";
import axios from "axios";
import md5 from "md5";

import { authSlice, LOGIN, LOGOUT } from "./redux";
import { processesSlice } from "../processes/redux";

// todo: maybe close Realm Web / Mongo on logout

export function* login() {
  while (true) {
    const { password } = yield take(LOGIN);

    try {
      yield put(processesSlice.actions.update({ key: LOGIN, inProcess: true }));

      const result = yield call(axios, {
        url: "/api/login",
        method: "post",
        data: {
          password: md5(password),
        },
      });

      const mongoJwt = result.data.mongoJwt;

      localStorage.setItem("isAuthorized", true);
      localStorage.setItem("mJwt", mongoJwt);

      yield put(authSlice.actions.setIsAuthorized(true));
      yield put(authSlice.actions.setMongoJwt(mongoJwt));

      yield put(
        processesSlice.actions.update({ key: LOGIN, inProcess: false })
      );
    } catch (e) {
      yield put(
        processesSlice.actions.update({
          key: LOGIN,
          hasError: true,
          errorMessage: e.message,
        })
      );
      console.log(e);
    }
  }
}

export function* logout() {
  while (true) {
    yield take(LOGOUT);

    try {
      yield put(
        processesSlice.actions.update({ key: LOGOUT, inProcess: true })
      );

      yield call(axios, { url: "/api/logout", method: "get" });

      localStorage.removeItem("isAuthorized");
      localStorage.removeItem("mJwt");

      yield put(authSlice.actions.setIsAuthorized(false));
      yield put(authSlice.actions.setMongoJwt(null));

      yield put(
        processesSlice.actions.update({ key: LOGOUT, inProcess: false })
      );
    } catch (e) {
      yield put(
        processesSlice.actions.update({
          key: LOGOUT,
          hasError: true,
          errorMessage: e.message,
        })
      );
      alert(e);
    }
  }
}
