import { createSlice } from "@reduxjs/toolkit";

export const WATCH_PRICES = "CURRENT_PRICE::WATCH_PRICES";
export const SUBSCRIBE = "CURRENT_PRICE::SUBSCRIBE";
export const UNSUBSCRIBE = "CURRENT_PRICE::UNSUBSCRIBE";
export const UNSUBSCRIBE_BY_ID = "CURRENT_PRICE::UNSUBSCRIBE_BY_ID";

export const subscribeToPrice = ({ symbol, id, platform } = {}) => ({
  type: SUBSCRIBE,
  symbol,
  id,
  platform,
});
export const unsubscribeById = ({ id } = {}) => ({
  type: UNSUBSCRIBE_BY_ID,
  id,
});
export const unsubscribeFromPrice = ({ symbol, id, platform }) => ({
  type: UNSUBSCRIBE,
  symbol,
  id,
  platform,
});

export const currentPriceSlice = createSlice({
  name: "currentPrice",
  initialState: {},
  reducers: {
    setSymbolPrice: (state, action) => {
      state[action.payload.symbol] = action.payload.price;
    },
  },
});
