import { IconPlus, IconX } from "@tabler/icons-react";
import { Button, Grid, NumberInput } from "@mantine/core";

function SellOnPercent({ strategy, updateStrategy, removeStrategy }) {
  return (
    <Grid>
      <Grid.Col
        lg={3}
        span={4}
        style={{ display: "flex", alignItems: "center" }}
      >
        <Button
          rightIcon={<IconX />}
          variant="white"
          color="dark"
          onClick={() => removeStrategy(strategy)}
        >
          Sell On (%)
        </Button>
      </Grid.Col>
      <Grid.Col lg={3} span={4}>
        <NumberInput
          label="Down %"
          placeholder="Down %"
          min={0}
          precision={1}
          step={1}
          value={strategy.params.down}
          onChange={(value) =>
            updateStrategy({
              ...strategy,
              params: { ...strategy.params, down: value },
            })
          }
        />
      </Grid.Col>
      <Grid.Col lg={3} span={4}>
        <NumberInput
          label="Up %"
          placeholder="Up %"
          min={0}
          precision={1}
          step={1}
          value={strategy.params.up}
          onChange={(value) =>
            updateStrategy({
              ...strategy,
              params: { ...strategy.params, up: value },
            })
          }
        />
      </Grid.Col>
    </Grid>
  );
}

SellOnPercent.type = "sell";
SellOnPercent.color = "teal";
SellOnPercent.model = {
  name: "sell_on_percent",
  title: "Sell on percent",
  params: { down: "", up: "" },
};
SellOnPercent.paramsTitle = (strategy) => {
  if (strategy.params.down !== "" && strategy.params.up !== "") {
    return `-${strategy.params.down}% - +${strategy.params.up}%`;
  } else if (strategy.params.down !== "") {
    return `<= -${strategy.params.down}%`;
  } else if (strategy.params.up !== "") {
    return `>= +${strategy.params.up}%`;
  } else {
    return `-`;
  }
};

SellOnPercent.Button = (props) => {
  return (
    <Button variant="default" color="teal" leftIcon={<IconPlus />} {...props}>
      Sell On (%)
    </Button>
  );
};

export default SellOnPercent;
