import { React } from "react";
import { Select, Text } from "@mantine/core";

export default function AmountPercentPicker({ value, onChange, ...props }) {
  return (
    <Select
      label={<Text fw={700}>Amount (%)</Text>}
      placeholder="Amount (%)"
      maxDropdownHeight={400}
      data={["0", "25", "50", "75", "100"]}
      value={value}
      onChange={onChange}
      {...props}
    />
  );
}
