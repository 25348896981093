import { useState } from "react";
import { createStyles } from "@mantine/core";
import { useInView } from "react-intersection-observer";

const useStyles = createStyles((theme) => ({
  wrapper: {
    position: "relative",
  },
  link: {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
  },
}));

export default function TradingViewMiniChartWidget({
  symbol,
  height = "100px",
  width = "200px",
}) {
  const { classes } = useStyles();

  const [show, setShow] = useState(false);

  const { ref } = useInView({
    threshold: 0,
    triggerOnce: true,
    onChange: (inView) => {
      if (inView) setShow(true);
    },
  });

  return (
    <div className={classes.wrapper} ref={ref}>
      {show && (
        <>
          <a
            className={classes.link}
            href={`https://www.tradingview.com/chart/b865o21d/?symbol=BINANCE:${symbol}`}
            target="_blank"
          ></a>
          <iframe
            height={height}
            width={width}
            src={`https://s.tradingview.com/embed-widget/single-quote/#{"width":350,"height":220,"symbol":"BINANCE:${symbol}","dateRange":"12M","isTransparent":true,"autosize":true,"largeChartUrl":"","utm_source":"localhost","utm_medium":"widget","utm_campaign":"mini-symbol-overview", "locale": "in"}`}
            frameBorder="0"
          ></iframe>
        </>
      )}
    </div>
  );
}
