import { createSlice } from "@reduxjs/toolkit";

// todo: slice is used instead, consider to use pure actions
// export const UPDATE = "PROCESSES::UPDATE";
// export const updateProcess = ({
//   key,
//   inProcess,
//   hasError,
//   errorMessage,
// } = {}) => ({
//   type: UPDATE,
//   key,
//   inProcess,
//   hasError,
//   errorMessage,
// });

export const processesSlice = createSlice({
  name: "processes",
  initialState: {},
  reducers: {
    update: (state, action) => {
      return {
        ...state,
        [action.payload.key]: {
          inProcess: !!action.payload.inProcess,
          hasError: !!action.payload.hasError,
          errorMessage: action.payload.errorMessage || "",
        },
      };
    },
  },
});
