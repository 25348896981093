import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Alert,
  Box,
  Text,
  Button,
  Grid,
  Badge,
  Anchor,
  Tooltip,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import {
  IconReload,
  IconAlertCircle,
  IconRobot,
  IconMasksTheater,
} from "@tabler/icons-react";
import useWebSocket from "react-use-websocket";

import TradingViewAdvancedChartWidget from "../../common/components/TradingViewAdvancedChartWidget";
import { UPDATE_ORDER, updateOrder } from "../../common/data/orders/redux";
import * as helpers from "./../../common/helpers";
import {
  toFixed,
  toK,
  toFixedMeaningful,
  getSpotTradingPageUrl,
} from "./../../common/helpers";

import { StrategyPickerModal, StrategyPickerBoard } from "../StrategyPicker";
import {
  subscribeToPrice,
  unsubscribeFromPrice,
} from "../../common/data/currentPrice/redux";

import * as Buttons from "./Buttons";

function Symbol({ order }) {
  const { symbol, platform, isFake } = order;

  const [duration, setDuration] = useState(
    helpers.getDuration(new Date(), order.created)
  );

  useEffect(() => {
    const id = setInterval(
      () => setDuration(helpers.getDuration(new Date(), order.created)),
      1000
    );

    return () => clearInterval(id);
  }, []);

  return (
    <Box
      style={{
        display: "flex",
        width: "100%",
        alignItems: "flex-end",
        justifyContent: "center",
      }}
    >
      <Badge mr="0.5rem" color="gray.9">
        {order.isFake ? "F" : "R"} & {order.env === "prod" ? "P" : "L"}
      </Badge>
      <Text align="center" fw={700} size="sm">
        <Anchor
          style={{ textDecoration: "none" }}
          href={helpers.getSpotTradingPageUrl(symbol, platform)}
          target="_blank"
          color={isFake ? "gray.9" : "teal"}
        >
          {symbol.replace("USDT", "")}
        </Anchor>
      </Text>
      <Tooltip label={`${new Date(order.created).toLocaleString()}`}>
        <Text
          ml="0.5rem"
          align="center"
          fw={700}
          size="sm"
          style={{ cursor: "pointer" }}
          c="dimmed"
        >
          {duration}
        </Text>
      </Tooltip>
    </Box>
  );
}

function Status({ order }) {
  const { symbol, isFake } = order;

  const sellTimes = useMemo(() => {
    return order.ops?.reduce((result, op) => {
      return op.action === "sell" ? result + 1 : result;
    }, 0);
  }, [order.ops?.length]);

  const isCreatedByBot = order.source === "bot";

  return (
    <Box
      style={{
        display: "flex",
        width: "100%",
        alignItems: "flex-end",
        justifyContent: "center",
      }}
    >
      {isCreatedByBot && (
        <Badge ml="0.5rem" color={isFake ? "gray.9" : "teal.9"}>
          <IconRobot
            style={{
              display: "flex",
              alignItems: "center",
              width: "0.8rem",
              height: "0.8rem",
            }}
          />
        </Badge>
      )}
      {sellTimes >= 0 && (
        <Badge
          ml="0.5rem"
          color={isFake ? "gray.9" : "teal.9"}
          leftSection={
            <IconReload
              style={{
                display: "flex",
                alignItems: "center",
                width: "0.8rem",
                height: "0.8rem",
              }}
            />
          }
        >
          {sellTimes}
        </Badge>
      )}
      {order.status === "buy" && (
        <Badge ml="0.5rem" color={order.isFake ? "gray.9" : "teal.9"}>
          BUY
        </Badge>
      )}
      {order.status === "sell" && (
        <Badge ml="0.5rem" color={order.isFake ? "gray.9" : "teal.9"}>
          SELL
        </Badge>
      )}
      {order.status === "stopped" && (
        <Badge ml="0.5rem" color={order.isFake ? "gray.9" : "teal.9"}>
          STOPPED
        </Badge>
      )}
      {order.status === "executed" && (
        <Tooltip label={`${new Date(order.sell.time).toLocaleString()}`}>
          <Badge
            ml="0.5rem"
            color={order.isFake ? "gray.9" : "teal.9"}
            style={{ cursor: "pointer" }}
          >
            DONE
          </Badge>
        </Tooltip>
      )}
    </Box>
  );
}

function BuyFields({ order, currentPrice, isActive, isStopped, isExecuted }) {
  let buyAmount, buyPureAmount, buyPrice, invested, summary;
  let pureSymbol = order.symbol.replace("USDT", "");

  if (order.amount) {
    buyAmount = `Buy: ${toK(order.amount)} ${pureSymbol} (${
      currentPrice ? toFixed(order.amount * currentPrice, 0) : 0
    }$)`;
    buyPureAmount = `${order.amount} ${pureSymbol}`;
  } else if (order.amountUsd) {
    buyAmount = `Buy: ${order.amountUsd}$ (${
      currentPrice
        ? toK(toFixedMeaningful(order.amountUsd / currentPrice, 4))
        : 0
    } ${pureSymbol})`;
    buyPureAmount = `${order.amountUsd / currentPrice} ${pureSymbol}`;
  }

  buyPrice = `Curr. price: ${currentPrice}`;

  invested = toFixed(
    order.ops?.reduce((sum, op) => {
      return op.action === "buy" ? sum + op.amountUsd : sum;
    }, 0) || 0,
    0
  );

  summary = toFixed(order.summary?.profit || 0, 1);

  return (
    <Grid>
      <Grid.Col lg={4}>
        <Grid>
          <Grid.Col lg={12}>
            <Symbol order={order} />
          </Grid.Col>
          <Grid.Col lg={12}>
            <Status order={order} />
          </Grid.Col>
        </Grid>
      </Grid.Col>
      <Grid.Col lg={4}>
        <Grid>
          <Grid.Col lg={12}>
            <Tooltip label={buyPureAmount}>
              <Text
                align="center"
                fw={700}
                size="sm"
                style={{ cursor: "pointer" }}
              >
                {buyAmount}
              </Text>
            </Tooltip>
          </Grid.Col>
          <Grid.Col lg={12}>
            <Text align="center" fw={700} size="sm">
              {buyPrice}
            </Text>
          </Grid.Col>
        </Grid>
      </Grid.Col>
      <Grid.Col lg={4}>
        <Grid>
          <Grid.Col lg={12}>
            <Text align="center" fw={700} size="sm">
              Invested: {invested}$
            </Text>
          </Grid.Col>
          <Grid.Col lg={12}>
            <Text align="center" fw={700} size="sm">
              Summary: {summary}$
            </Text>
          </Grid.Col>
        </Grid>
      </Grid.Col>
    </Grid>
  );
}

function SingleSellFields({ order, isActive, isExecuted, currentPrice }) {
  let sellAmount, sellPrice, pureSellAmount;
  let pureSymbol = order.symbol.replace("USDT", "");

  if (isExecuted) {
    pureSellAmount = `${order.sell.amount} ${pureSymbol}`;
    sellAmount = `Sold: ${toK(order.sell.amount)} ${pureSymbol} (${toFixed(
      order.sell.amountUsd,
      1
    )}$)`;

    sellPrice = `Sell price: ${toFixedMeaningful(order.sell.price, 4)}`;
  } else {
    if (order.amount) {
      pureSellAmount = `${order.amount} ${pureSymbol}`;
      sellAmount = `Sell: ${toK(order.amount)} = ${toFixed(
        order.amount * currentPrice,
        1
      )} usd`;
    } else if (order.amountUsd) {
      pureSellAmount = `${order.amountUsd / currentPrice} ${pureSymbol}`;
      sellAmount = `Sell: ${order.amountUsd} usd`;
    } else if (order.amountPercent) {
      pureSellAmount = "Not available";
      sellAmount = `Sell: ${order.amountPercent} %`;
    }

    sellPrice = `Curr. price: ${currentPrice}`;
  }

  return (
    <Grid>
      <Grid.Col lg={6}>
        <Grid>
          <Grid.Col lg={12}>
            <Symbol order={order} />
          </Grid.Col>
          <Grid.Col lg={12}>
            <Status order={order} />
          </Grid.Col>
        </Grid>
      </Grid.Col>
      <Grid.Col lg={6}>
        <Grid>
          <Grid.Col lg={12}>
            <Tooltip label={pureSellAmount}>
              <Text
                align="center"
                fw={700}
                size="sm"
                style={{ cursor: "pointer" }}
              >
                {sellAmount}
              </Text>
            </Tooltip>
          </Grid.Col>
          <Grid.Col lg={12}>
            <Text align="center" fw={700} size="sm">
              {sellPrice}
            </Text>
          </Grid.Col>
        </Grid>
      </Grid.Col>
    </Grid>
  );
}

function BuyToSellFields({
  order,
  currentPrice,
  isActive,
  isStopped,
  isExecuted,
  setProfits,
}) {
  let buyPrice,
    sellPrice,
    sellAmount,
    pureSellAmount,
    gain,
    profit,
    diffInPercent,
    summary,
    invested,
    buyTimes;

  let pureSymbol = order.symbol.replace("USDT", "");

  buyPrice = `Buy price: ${toFixedMeaningful(order.buy.price, 4)}`;

  if (isExecuted) {
    sellPrice = `Sell price: ${toFixedMeaningful(order.sell.price, 4)}`;
    sellAmount = `Sold: ${toK(
      toFixedMeaningful(order.sell?.amount, 4)
    )} ${pureSymbol}`;
    pureSellAmount = `${order.sell?.amount} ${pureSymbol}`;

    diffInPercent = helpers.diffToPercent(
      order.sell.price / order.buy.price,
      1
    );
    profit = order.stat.profit;
    summary = toFixed(order.summary?.profit || 0, 1);
  } else {
    sellPrice = `Curr. price: ${currentPrice || 0}`;
    sellAmount = `Sell: ${toK(
      toFixedMeaningful(order.buy?.amount, 4)
    )} ${pureSymbol}`;
    pureSellAmount = `${order.buy?.amount} ${pureSymbol}`;

    diffInPercent = currentPrice
      ? helpers.diffToPercent(currentPrice / order.buy.price, 1)
      : 0;
    gain = currentPrice
      ? toFixed(
          order.buy.amount * currentPrice - order.buy.amount * order.buy.price,
          1
        )
      : 0;
    profit = toFixed(
      gain - order.buy.amount * order.buy.price * 0.00075 * 2,
      1
    );

    summary = toFixed((order.summary?.profit || 0) + profit, 1);
  }

  invested = toFixed(
    order.ops?.reduce((sum, op) => {
      return op.action === "buy" ? sum + op.amountUsd : sum;
    }, 0) || 0,
    0
  );

  useEffect(
    function updateSymbolInDocumentTitle() {
      setProfits((state) => ({
        ...state,
        [order._id]: { symbol: order.symbol, profit },
      }));
    },
    [profit]
  );

  useEffect(function removeSymbolFromDocumentTitle() {
    return () => {
      setProfits((state) => {
        const updated = { ...state };

        delete updated[order._id];

        return updated;
      });
    };
  }, []);

  return (
    <Grid>
      <Grid.Col lg={3}>
        <Grid>
          <Grid.Col lg={12} span={6}>
            <Symbol order={order} />
          </Grid.Col>
          <Grid.Col lg={12} span={6}>
            <Status order={order} />
          </Grid.Col>
        </Grid>
      </Grid.Col>
      <Grid.Col lg={3}>
        <Grid>
          <Grid.Col lg={12} span={6}>
            <Text align="center" fw={700} size="sm">
              {buyPrice}
            </Text>
          </Grid.Col>
          <Grid.Col lg={12} span={6}>
            <Text align="center" fw={700} size="sm">
              {sellPrice}
            </Text>
          </Grid.Col>
        </Grid>
      </Grid.Col>
      <Grid.Col lg={3}>
        <Grid>
          <Grid.Col lg={12} span={6}>
            <Tooltip label={pureSellAmount}>
              <Text
                align="center"
                fw={700}
                size="sm"
                style={{ cursor: "pointer" }}
              >
                {sellAmount}
              </Text>
            </Tooltip>
          </Grid.Col>
          <Grid.Col lg={12} span={6}>
            {/*todo*/}
            <Text
              align="center"
              fw={700}
              size="sm"
              color={profit >= 0 ? "teal" : "red"}
            >
              {(diffInPercent > 0 ? `+${diffInPercent}` : diffInPercent) || 0}%
              / {profit > 0 ? `+${profit}` : profit}$
            </Text>
          </Grid.Col>
        </Grid>
      </Grid.Col>
      <Grid.Col lg={3}>
        <Grid>
          <Grid.Col lg={12} span={6}>
            <Text align="center" fw={700} size="sm">
              Invested: {invested}$
            </Text>
          </Grid.Col>
          <Grid.Col lg={12} span={6}>
            <Text align="center" fw={700} size="sm">
              Summary: {summary}$
            </Text>
          </Grid.Col>
        </Grid>
      </Grid.Col>
    </Grid>
  );
}

export default function Order({ order, isFake, setProfits }) {
  const dispatch = useDispatch();

  const isSmallerThanLarge = useMediaQuery("(max-width: 75em)");
  const isSmallerThanSmall = useMediaQuery("(max-width: 48em)");

  const tradePrice = useSelector((state) => state.currentPrice[order.symbol]);

  const [buyStrategies, setBuyStrategies] = useState([]);
  const [sellStrategies, setSellStrategies] = useState([]);
  const [repeatStrategies, setRepeatStrategies] = useState([]);
  const [showStrategyPicker, setShowStrategyPicker] = useState(false);

  const updateOrderProcess = useSelector(
    (state) => state.processes[`${UPDATE_ORDER}-${order._id}`]
  );

  useEffect(() => {
    if (!["buy", "sell", "stopped"].includes(order.status)) {
      return;
    }

    dispatch(
      subscribeToPrice({
        symbol: order.symbol,
        platform: order.platform,
        id: order._id,
      })
    );

    return () =>
      dispatch(
        unsubscribeFromPrice({
          symbol: order.symbol,
          platform: order.platform,
          id: order._id,
        })
      );
  }, []);

  useEffect(() => {
    if (order.status === "executed") {
      dispatch(
        unsubscribeFromPrice({
          symbol: order.symbol,
          platform: order.platform,
          id: order._id,
        })
      );
    }
  }, [order.status]);

  // const { sendMessage, lastMessage, readyState } = useWebSocket(
  //   `wss://stream.binance.com:9443/ws/${order.symbol.toLowerCase()}@kline_1s`
  // );
  // const data = lastMessage && JSON.parse(lastMessage.data);
  // const klinePrice = parseFloat(data?.k?.c) || 0;
  const klinePrice = 0;

  let currentPrice = tradePrice || klinePrice;

  const openStrategyPickerModal = () => {
    setBuyStrategies(JSON.parse(JSON.stringify(order.buyStrategies || [])));
    setSellStrategies(JSON.parse(JSON.stringify(order.sellStrategies || [])));
    setRepeatStrategies(
      JSON.parse(JSON.stringify(order.repeatStrategies || []))
    );
    setShowStrategyPicker(true);
  };

  const update = () => {
    dispatch(
      updateOrder({
        id: order._id,
        updateOb: {
          buyStrategies: buyStrategies,
          sellStrategies: sellStrategies,
          repeatStrategies: repeatStrategies,
        },
        onSuccess: () => setShowStrategyPicker(false),
      })
    );
  };

  const closeError = () => {
    dispatch(
      updateOrder({
        id: order._id,
        updateOb: {
          errors: [
            ...order.errors.slice(0, order.errors.length - 1),
            { ...order.errors[order.length - 1], isClosed: true },
          ],
        },
        onSuccess: () => setShowStrategyPicker(false),
      })
    );
  };

  const isActive = order.status === "buy" || order.status === "sell";
  const isStopped = order.status === "stopped";
  const isExecuted = order.status === "executed";
  const isBuy =
    order.start === "buy" &&
    (order.status === "buy" ||
      (order.status === "stopped" && order.stoppedOn === "buy"));
  const isBuyToSell =
    order.start === "buy" &&
    (order.status === "sell" ||
      (order.status === "stopped" && order.stoppedOn === "sell") ||
      order.status === "executed");
  const isSingleSell =
    order.start === "sell" &&
    (order.status === "sell" ||
      (order.status === "stopped" && order.stoppedOn === "sell") ||
      order.status === "executed");

  const lastError = order.errors?.[order.errors.length - 1];

  return (
    <>
      <StrategyPickerModal
        show={showStrategyPicker}
        currentPrice={currentPrice}
        buyStrategies={buyStrategies}
        sellStrategies={sellStrategies}
        repeatStrategies={repeatStrategies}
        setBuyStrategies={setBuyStrategies}
        setSellStrategies={setSellStrategies}
        setRepeatStrategies={setRepeatStrategies}
        onClose={() => setShowStrategyPicker(false)}
        onSubmit={update}
        process={updateOrderProcess}
        disableBuyStrategies={isSingleSell}
        disableRepeatStrategies={isSingleSell}
      />
      <Grid>
        <Grid.Col span={12}>
          <TradingViewAdvancedChartWidget
            symbol={order.symbol}
            platform={order.platform}
            width="100%"
            height={isSmallerThanLarge ? "400px" : "500px"}
          />
        </Grid.Col>
        {lastError && !lastError?.isClosed && (
          <Grid.Col span={12}>
            <Alert
              icon={<IconAlertCircle size="1rem" />}
              color="red"
              variant="light"
            >
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Text fw={700}>
                  Error: {lastError.message} (
                  {new Date(lastError.date).toLocaleString()})
                </Text>
                <Button size="xs" compact color="red" onClick={closeError}>
                  Ok
                </Button>
              </Box>
            </Alert>
          </Grid.Col>
        )}
        <Grid.Col span={12}>
          {isBuy && (
            <BuyFields
              order={order}
              currentPrice={currentPrice}
              isActive={order.status === "buy"}
              isStopped={order.status === "stopped"}
              isExecuted={order.status === "executed"}
            />
          )}
          {isSingleSell && (
            <SingleSellFields
              order={order}
              currentPrice={currentPrice}
              isActive={order.status === "sell"}
              isStopped={order.status === "stopped"}
              isExecuted={order.status === "executed"}
            />
          )}
          {isBuyToSell && (
            <BuyToSellFields
              order={order}
              currentPrice={currentPrice}
              isActive={order.status === "sell"}
              isStopped={order.status === "stopped"}
              isExecuted={order.status === "executed"}
              setProfits={setProfits}
            />
          )}
        </Grid.Col>
        <Grid.Col span={12}>
          <StrategyPickerBoard
            buyStrategies={order.buyStrategies}
            sellStrategies={order.sellStrategies}
            repeatStrategies={order.repeatStrategies}
            isFake={order.isFake}
            openModal={openStrategyPickerModal}
            disableButtons={isExecuted}
          />
        </Grid.Col>
        {isActive && (
          <>
            <Grid.Col span={6}>
              <Buttons.StopButton order={order} />
            </Grid.Col>
            {order.status === "buy" && (
              <Grid.Col span={6}>
                <Buttons.BuyButton order={order} />
              </Grid.Col>
            )}
            {order.status === "sell" && (
              <Grid.Col span={6}>
                <Buttons.SellButton order={order} />
              </Grid.Col>
            )}
          </>
        )}
        {isStopped && (
          <>
            <Grid.Col span={4}>
              <Buttons.ResumeButton order={order} />
            </Grid.Col>
            <Grid.Col span={4}>
              <Buttons.CloseButton order={order} />
            </Grid.Col>
            <Grid.Col span={4}>
              <Buttons.DeleteButton order={order} />
            </Grid.Col>
          </>
        )}
        {isExecuted && (
          <>
            <Grid.Col span={6}>
              <Buttons.CloseButton order={order} />
            </Grid.Col>
            <Grid.Col span={6}>
              <Buttons.DeleteButton order={order} />
            </Grid.Col>
          </>
        )}
      </Grid>
    </>
  );
}
