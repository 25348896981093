import { Container } from "@mantine/core";

export default function Main({ children, ...props }) {
  return (
    <main {...props}>
      <Container size="110rem" h="100%" py="1rem">
        {children}
      </Container>
    </main>
  );
}
