import { configureStore } from "@reduxjs/toolkit";
import { createLogger } from "redux-logger";
import createSagaMiddleware from "redux-saga";

import { authSlice } from "./common/data/auth/redux";
import { tokensSlice } from "./common/data/tokens/redux";
import { ordersSlice } from "./common/data/orders/redux";
import { processesSlice } from "./common/data/processes/redux";
import { currentPriceSlice } from "./common/data/currentPrice/redux";
import { dailyStatsSlice } from "./Daily/redux";

import rootSaga from "./root-saga";

const rootReducer = {
  auth: authSlice.reducer,
  tokens: tokensSlice.reducer,
  orders: ordersSlice.reducer,
  processes: processesSlice.reducer,
  currentPrice: currentPriceSlice.reducer,
  dailyStats: dailyStatsSlice.reducer,
};

let store;

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware];

if (process.env.NODE_ENV === "development") {
  const logger = createLogger({
    collapsed: true,
    predicate: (getState, action) =>
      action.type !== "currentPrice/setSymbolPrice",
  });
  middlewares.push(logger);
}

store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(middlewares),
});

sagaMiddleware.run(rootSaga);

export { store };
