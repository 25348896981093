import React, { useState } from "react";
import { Alert, Stack, Box, Center, Group, Button } from "@mantine/core";
import {
  IconAlertCircle,
  IconAlarmPlus,
  IconAlarmMinus,
  IconCopy,
} from "@tabler/icons-react";

import PushNotifications from "../common/sevices/push-notifications";

export default function Login() {
  const [isEnabled, setIsEnabled] = useState(
    () => !!localStorage.getItem("is_push_notifications_enabled")
  );
  const [token, setToken] = useState(null);
  const [error, setError] = useState("");
  const [inProcess, setInProcess] = useState(false);

  const subscribe = async () => {
    try {
      setInProcess(true);
      const token = await PushNotifications.subscribe();

      setToken(token);
      setIsEnabled(true);
    } catch (e) {
      setError(e.message);
    } finally {
      setInProcess(false);
    }
  };

  const unsubscribe = async () => {
    try {
      setInProcess(true);
      await PushNotifications.unsubscribe();

      setToken(null);
      setIsEnabled(false);
    } catch (e) {
      setError(e.message);
    } finally {
      setInProcess(false);
    }
  };

  return (
    <Center>
      <Box miw={250} mx="auto">
        <Stack>
          {isEnabled ? (
            <Button
              color="red"
              maw="300px"
              leftIcon={<IconAlarmMinus />}
              onClick={unsubscribe}
              loading={inProcess}
            >
              Unsubscribe from notifications
            </Button>
          ) : (
            <Button
              maw="300px"
              leftIcon={<IconAlarmPlus />}
              onClick={subscribe}
              loading={inProcess}
            >
              Subscribe to notifications
            </Button>
          )}
          {token && (
            <Button
              variant="default"
              maw="300px"
              leftIcon={<IconCopy />}
              onClick={() => navigator.clipboard.writeText(token)}
            >
              Copy FCM token
            </Button>
          )}
          {error && (
            <Alert
              icon={<IconAlertCircle size="1rem" />}
              title="Error!"
              color="red"
            >
              {error}
            </Alert>
          )}
        </Stack>
      </Box>
    </Center>
  );
}
